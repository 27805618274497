<template>
  <admin-settings v-if="isAdmin" />
  <team-admin-settings v-else-if="isTeamAdmin || isCupAdmin" />
  <div v-else>
    <h3>Geschlossener Bereich</h3>
    <h4>Login erforderlich</h4>
  </div>
</template>
<script>
import AdminSettings from "../admin/AdminSettings.vue";
import TeamAdminSettings from "../teamAdmin/TeamAdminSettings.vue";
export default {
  name: "Settings",
  components: {
    AdminSettings,
    TeamAdminSettings,
  },
  computed: {
    loggedIn() {
      var user = localStorage.getItem("user");
      return user && user != null;
    },
    isAdmin() {
      if (!this.loggedIn) {
        return false;
      }
      var user = JSON.parse(localStorage.getItem("user"));
      return user && user.roles && user.roles.includes("admin");
    },
    isTeamAdmin() {
      if (!this.loggedIn) {
        return false;
      }
      var user = JSON.parse(localStorage.getItem("user"));
      return (
        user &&
        user.roles &&
        !user.roles.includes("admin") &&
        user.roles.includes("teamAdmin")
      );
    },
    isCupAdmin() {
      if (!this.loggedIn) {
        return false;
      }
      var user = JSON.parse(localStorage.getItem("user"));
      return (
        user &&
        user.roles &&
        !user.roles.includes("admin") &&
        user.roles.includes("cupAdmin")
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
