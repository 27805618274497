<template>
  <div class="col-md-12">
    <div class="card card-container">
      <div class="flex justify-content-center">
        <div class="card" style="min-width: 320px">
          <h5 class="text-center">Login BDL Management</h5>
          <form @submit.prevent="handleLogin" class="p-fluid">
            <div class="field">
              <div class="p-float-label">
                <InputText id="name" v-model="name" />
                <label for="name">Benutzername</label>
              </div>
            </div>
            <div class="field">
              <div class="p-float-label">
                <Password id="password" v-model="password" :feedback="false"> </Password>
                <label for="password">Passwort</label>
              </div>
            </div>
            <small v-if="loginFailed" class="p-error" style="font-size: 16px">{{
              loginFailedMessage
            }}</small>
            <Button type="submit" label="Login" class="mt-2" />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import Button from "primevue/button";
var crypt = require("../../services/Crypto");

export default {
  name: "Test",
  components: {
    InputText,
    Password,
    Button,
  },
  data() {
    return {
      name: "",
      password: "",
      loginFailed: false,
      loginFailedMessage: "",
      users: {},
    };
  },
  props: {},
  methods: {
    handleLogin() {
      var legacyPassword = crypt.encrypt(this.password);
      var user = {
        username: this.name,
        password: this.password,
        legacyPassword: legacyPassword,
      };
      this.$store.dispatch("auth/login", user).then(
        () => {
          this.$router.push("/home");
        },
        (error) => {
          if (error.code == "ERR_NETWORK") {
            this.loginFailedMessage = "Server aktuell nicht erreichbar.";
          } else if (error.response) {
            switch (error.response.status) {
              case 401:
                this.loginFailedMessage = "Ungültiger Benutzername/Passwort";
                break;
            }
          }
          if (!this.loginFailedMessage || this.loginFailedMessage.length < 1) {
            this.loginFailedMessage = "Unbekannter Fehler.";
          }
          this.loginFailed = true;
        }
      );
    },
  },
};
</script>

<style lang="css" scoped>
.field {
  margin-top: 2rem;
}
</style>
