<template>
  <div class="" style="margin-top: 10px">
    <div class="flex align-items-center justify-content-center">
      <MegaMenu
        :model="cupAdminMenuItems"
        :activeIndex="activeMenuItem ? activeMenuItem.index : -1"
        style="margin-top: 10px; min-width: 200px; display: flex; justify-content: center"
      >
        <template #item="{ item }">
          <a
            class="p-menuitem-link"
            @click="onItemClick(item)"
            :style="
              'display: flex; flex-direction: column; padding: 5px 6px;border: solid' +
              (item.active ? ';color: #2196f3' : '#dee2e6') +
              ';border-width: 0 0 3px 0;' +
              (item.active ? ';color: #2196f3' : '')
            "
          >
            <span
              v-if="item.icon && item.badged && getBadgeCount(item)"
              v-badge="getBadgeCount(item)"
              :class="item.icon + ' p-overlay-badge'"
              style="font-size: 38px"
            ></span>
            <span v-else-if="item.icon" :class="item.icon" style="font-size: 38px"></span>
            <span
              class="p-menuitem-text"
              :style="
                'margin-top: 8px; font-size: 12px; font-weight: 600;' +
                (item.active ? ';color: #2196f3' : '')
              "
              >{{ item.label }}</span
            >
          </a>
        </template>
      </MegaMenu>
    </div>
    <div v-if="activeMenuItem && activeMenuItem.name == 'pokal'">
      <div v-if="nextRoundDrawPossible">
        <ProgressSpinner v-if="nextRoundDrawInProgress" style="margin-top: 20px" />
        <Button
          v-else
          style="margin: 20px 2px"
          label="Auslosung der nächsten Pokalrunde ausführen"
          icon="pi pi-receipt"
          class="p-button-rounded p-button-warn mb2"
          @click="showNextDrawDialog()"
        />
      </div>
      <div v-else class="flex flex-column align-items-center justify-content-center">
        <Button
          style="margin: 20px 2px 0px 2px"
          label="Auslosung der nächsten Pokalrunde ausführen"
          icon="pi pi-receipt"
          class="p-button-rounded p-button-warn mb2"
          disabled
        />
        <Message
          severity="warn"
          :closable="false"
          style="margin: 0px; margin-bottom: 10px; max-width: 350px"
          >Noch nicht alle Spiele abgeschlossen</Message
        >
      </div>

      <TeamAdminCupView :currentTeamId="0" ref="teamAdminCupView" />
    </div>
    <div v-if="activeMenuItem && activeMenuItem.name == 'nachrichten'">
      <div
        class="flex flex-wrap card-container align-items-center justify-content-center"
        style="margin-top: 10px"
      >
        <div style="max-width: 1000px; width: 100%">
          <DataTable
            class="p-datatable-sm"
            :rowClass="conversationRowClass"
            :value="teamConversations"
            stripedRows
            responsiveLayout="stack"
            breakpoint="550px"
            style="margin-top: 10px"
          >
            <Column header="Letzte Änderung">
              <template #body="slotProps">
                {{ formatDateTimeInfo(slotProps.data.updateTimestamp) }}
              </template>
            </Column>
            <Column field="subject" header="Betreff"></Column>
            <Column field="requestParticipant" header="Ersteller"></Column>
            <Column header="Ersteller gelesen am">
              <template #body="slotProps">
                {{ formatDateTimeInfo(slotProps.data.requestReadTimestamp) }}
              </template>
            </Column>
            <Column field="answerParticipant" header="Angefragter"></Column>
            <Column header="Angefragter gelesen am">
              <template #body="slotProps">
                {{ formatDateTimeInfo(slotProps.data.answerReadTimestamp) }}
              </template>
            </Column>
            <Column header="Antwort">
              <template #body="slotProps">
                <div v-html="formatAnswer(slotProps.data.resultingAnswer)" />
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>
  </div>
  <Dialog
    id="conversationDialog"
    modal
    v-model:visible="displayConversationDialog"
    style="min-width: 300px; max-width: 600px"
    :closable="true"
  >
    <template #header>
      <div
        class="align-items-center justify-content-center"
        style="font-size: 20px; text-align: center; width: 100%"
      >
        {{ conversation.subject }}
      </div>
    </template>
    <ConversationView
      :withHeader="false"
      :conversation="conversation"
      @answered="closeConversationDialog"
    />
  </Dialog>
  <Dialog v-model:visible="displayNextDrawDialog" :closable="false">
    <template #header>
      <h3>Neue Runde auslosen?</h3>
    </template>
    <span>Danach sind keine Änderungen mehr möglich.</span>
    <template #footer>
      <Button
        label="Ausführen"
        icon="pi pi-times"
        class="p-button-text"
        @click="executeNextRoundDraw"
      />
      <Button label="Abbrechen" icon="pi pi-check" @click="cancelNextRoundDraw" />
    </template>
  </Dialog>
</template>

<script>
import { useMenuService } from "../../services/MenuService/UseMenuService";
import ServerConnection from "../../services/ServerConnection/ServerConnection";
import FailureHandler from "../../services/ServerConnection/FailureHandler";
import ConversationView from "../conversation/ConversationView";
import MegaMenu from "primevue/megamenu";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import ProgressSpinner from "primevue/progressspinner";
import { useWizardService } from "../../services/WizardService/UseWizardService";
import TeamAdminCupView from "../teamAdmin/TeamAdminCupView";
import Message from "primevue/message";
const cupMenuItem = {
  index: 2,
  name: "pokal",
  label: "Pokal",
  icon: "pi pi-fw pi-sitemap",
  style: "padding: 0px 1px",
  active: false,
  items: [],
};
const conversationMenuItem = {
  index: 3,
  name: "nachrichten",
  label: "Nachrichten",
  icon: "pi pi-fw pi-bell",
  badged: true,
  style: "padding: 0px 1px",
  active: false,
  items: [],
};

export default {
  name: "CupAdminDashBoard",
  components: {
    ConversationView,
    MegaMenu,
    DataTable,
    Column,
    Button,
    Dialog,
    TeamAdminCupView,
    Message,
    ProgressSpinner,
  },
  computed: {
    loggedIn() {
      var user = localStorage.getItem("user");
      return user && user != null;
    },
    showSignOffDialog() {
      return this.signOffPlayer && this.signOffPlayer != null;
    },
    cupAdminMenuItems() {
      var menuItems = [cupMenuItem, conversationMenuItem];
      var activeMenuItemName;
      if (this.activeMenuItem) {
        activeMenuItemName = this.activeMenuItem.name;
      } else {
        activeMenuItemName = localStorage.getItem("activeMenuItemName");
      }
      this.handleMenuItemAktiveByName(activeMenuItemName, menuItems);
      return menuItems;
    },
  },
  data() {
    return {
      menuService: useMenuService(),
      wizardService: useWizardService(),
      mannschaften: [],
      currentMannschaft: null,
      gameDayViewIndex: -1,
      teamConversations: [],

      activeMenuItem: null,
      editPlayer: null,
      signOffPlayer: null,
      displayConversationDialog: false,
      conversation: null,
      lastConversationLoadTimestamp: null,
      displayNextDrawDialog: false,
      nextRoundDrawPossible: false,
      nextRoundDrawInProgress: false,
    };
  },
  props: {},
  created() {
    if (!this.loggedIn) {
      this.$router.push("/login");
    }
    if (!this.activeMenuItem) {
      this.activeMenuItem = cupMenuItem;
    }
    this.loadNextRoundDrawPossible();
    this.updateMainMenu();
    this.loadConversations();
  },
  updated() {
    if (!this.loggedIn) {
      this.$router.push("/login");
    }
    if (!this.activeMenuItem) {
      this.activeMenuItem = cupMenuItem;
    }
    this.loadNextRoundDrawPossible();
    this.updateMainMenu();
    this.loadConversations();
  },
  methods: {
    loadNextRoundDrawPossible() {
      ServerConnection.get("data/cup/current/nextRoundDrawPossible")
        .then((response) => response.data)
        .then((data) => {
          this.nextRoundDrawPossible = data;
        })
        .catch((error) => {
          FailureHandler.handleServerFailure(this.$router, error);
        });
    },
    loadConversations() {
      var currentTimestamp = new Date().getTime();
      if (
        !this.lastConversationLoadTimestamp ||
        this.lastConversationLoadTimestamp < currentTimestamp - 60 * 1000
      ) {
        this.lastConversationLoadTimestamp = currentTimestamp;
        ServerConnection.get("conversations/cupAdmin/?offSet=0&limit=1000")
          .then((response) => response.data)
          .then((data) => {
            this.teamConversations = data;
          })
          .catch((error) => {
            FailureHandler.handleServerFailure(this.$router, error);
          });
      }
    },
    showNextDrawDialog() {
      this.displayNextDrawDialog = true;
    },
    cancelNextRoundDraw() {
      this.displayNextDrawDialog = false;
    },
    executeNextRoundDraw() {
      this.nextRoundDrawInProgress = true;
      this.displayNextDrawDialog = false;
      ServerConnection.put("data/cup/current/executeNextRoundDraw")
        .then(() => {
          this.$refs.teamAdminCupView.resetData();
          this.loadNextRoundDrawPossible();
          this.nextRoundDrawInProgress = false;
        })
        .catch((error) => {
          this.nextRoundDrawInProgress = false;
          FailureHandler.handleServerFailure(this.$router, error);
        });
    },
    updateMainMenu() {
      this.menuService.setMainMenuItemNames(["settings", "help", "logout"]);
    },
    onItemClick(item) {
      this.activeMenuItem = item;
    },
    handleMenuItemAktiveByName(name, items) {
      if (items && items.length > 0) {
        for (const item of items) {
          if (item && item.items && item.items.length && item.items.length.length > 0) {
            item.active = this.handleMenuItemAktiveByName(name, item.items);
          } else {
            item.active = item.name == name;
          }
        }
      }
    },
    getBadgeCount(item) {
      if (item && item.name == "nachrichten" && this.teamConversations) {
        return this.teamConversations.filter(
          (tm) =>
            !tm.updateTimestamp ||
            tm.updateTimestamp == null ||
            tm.requestReadTimestamp < tm.updateTimestamp ||
            tm.answerReadTimestamp < tm.updateTimestamp
        ).length;
      }
      return null;
    },
    showConversationDialog(conversation) {
      this.conversation = conversation;
      this.conversation.headerMessage = this.conversation.headerMessage.replaceAll(
        "\\n",
        "<br />"
      );
      this.displayConversationDialog = true;
      ServerConnection.put("conversation/" + this.conversation.id + "/markAsRead")
        .then(() => this.loadConversations())
        .catch((error) => {
          FailureHandler.handleServerFailure(this.$router, error);
        });
    },
    closeConversationDialog(answered) {
      this.displayConversationDialog = false;
      if (answered) {
        this.loadConversations();
      }
    },
    conversationRowClass(row) {
      if (row.answerRequested) {
        if (row.resultingAnswer && row.resultingAnswer != null) {
          return "conversationAnswered";
        }
        return "conversationAnswerRequested";
      }
      if (row.readTimestamp && row.readTimestamp != null) {
        return "conversationRead";
      }
      return "conversationNotRead";
    },
    formatAnswer(answer) {
      if (answer && answer != null && answer.length > 0) {
        return answer;
      }
      return "Keine Antwort";
    },
    formatDateTimeInfo(date) {
      if (date && date.length > 0) {
        var pattern = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2})/;
        return date.replace(pattern, "$3.$2.$1 $4:$5 Uhr");
      }
      return "";
    },
    currentTeamPlaysCup() {
      return this.currentMannschaft && this.currentMannschaft.registeredForCup;
    },
  },
};
</script>
