<template>
  <div class="flex align-items-center justify-content-center">
    <div
      class="flex flex-wrap card-container bg-blue-100 border-round"
      style="max-width: 800px; margin: 5px 1px"
    >
      <div class="flex flex-column align-items-center justify-content-center">
        <div class="flex align-items-center justify-content-center" style="width: 100%">
          <div style="margin: 10px; width: 100%">
            <div style="font-size: 24px; margin: 10px">
              {{ recordModel.baseRecord.representation }}
            </div>
          </div>
        </div>

        <div class="flex align-items-center justify-content-center" style="width: 100%">
          <div style="margin: 10px; width: 100%">
            <label style="font-size: 18px; text-decoration: underline"
              >Bemerkungen/Begründungen für den Vertreter der gegnerischen Mannschaft
            </label>
            <Textarea
              v-model="recordModel.notes"
              :autoResize="false"
              style="width: 100%; height: 30vh; margin-top: 5px"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AbstractWizardPage from "../../AbstractWizardPage";
import Textarea from "primevue/textarea";
export default {
  name: "SwitchCupHomeGuestRequestPage1",
  extends: AbstractWizardPage,
  components: {
    Textarea,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
