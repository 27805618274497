<template>
  <div class="flex align-items-center justify-content-center">
    <div
      class="flex flex-wrap card-container bg-blue-100 border-round"
      style="max-width: 800px; margin: 5px 1px"
    >
      <div class="flex flex-column align-items-center justify-content-center">
        <div class="flex align-items-center justify-content-center" style="width: 100%">
          <div style="margin: 10px; width: 100%">
            <div style="font-size: 24px; margin: 10px">
              {{ recordModel.baseRecord.representation }}
            </div>
          </div>
        </div>
        <div
          class="flex flex-column align-items-center justify-content-center"
          style="width: 100%"
        >
          <div class="flex align-items-center justify-content-center" style="width: 100%">
            <Calendar
              showIcon
              :manualInput="true"
              :showOnFocus="false"
              v-model="currentDate"
              :minDate="limits[0]"
              :maxDate="limits[1]"
              dateFormat="dd.mm.yy"
              showTime
              hourFormat="24"
              :hideOnDateTimeSelect="true"
              variant="filled"
            />
          </div>
          <div class="flex align-items-center justify-content-center" style="width: 100%">
            <Button
              style="margin: 2px 2px"
              label="Datum übernehmen"
              class="p-button-rounded p-button-warn mb2"
              @click="addCurrentDate"
            />
          </div>
          <h4 v-if="recordModel.selectedDates.length < 1" style="margin: 5px">
            Bitte mindestens einen alternativen Termin in die Vorschlagsliste aufnehmen
          </h4>
          <div class="flex align-items-center justify-content-center" style="width: 100%">
            <DataTable
              class="p-datatable-sm"
              :value="recordModel.selectedDates"
              stripedRows
              style="margin: 10px"
            >
              <Column header="Vorschlagene Datumswerte">
                <template #body="slotProps">
                  <span>
                    {{ slotProps.data.label }}
                  </span>
                </template>
              </Column>
              <Column>
                <template #body="slotProps">
                  <Button
                    style="margin: 0px"
                    icon="pi pi-times"
                    v-tooltip.top="'Datum wieder entfernen'"
                    class="p-button-rounded p-button-warn"
                    @click="removeSelectedDate(slotProps.data)"
                  />
                </template>
              </Column>
            </DataTable>
          </div>
          <div class="flex align-items-center justify-content-center" style="width: 100%">
            <div style="margin: 10px; width: 100%">
              <label style="font-size: 18px; text-decoration: underline"
                >Bemerkungen/Begründungen für den Vertreter der gegnerischen Mannschaft
              </label>
              <Textarea
                v-model="recordModel.notes"
                :autoResize="false"
                style="width: 100%; height: 30vh; margin-top: 5px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AbstractWizardPage from "../../AbstractWizardPage";

import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Calendar from "primevue/calendar";
import Button from "primevue/button";
import Textarea from "primevue/textarea";
export default {
  name: "ChangeCupDateRequestPage1",
  extends: AbstractWizardPage,
  components: {
    DataTable,
    Column,
    Calendar,
    Button,
    Textarea,
  },
  created() {
    this.calculateLimits();
    this.setCurrentDate();
    this.recordModel.selectedDates = [];
  },
  data() {
    return {
      limits: [],
      currentDate: null,
    };
  },
  props: {},
  methods: {
    setCurrentDate() {
      if (this.recordModel && this.recordModel.baseRecord) {
        this.currentDate = new Date(this.recordModel.baseRecord.date);
        if (
          this.recordModel.baseRecord.deviatingTime &&
          this.recordModel.baseRecord.deviatingTime > 0
        ) {
          this.currentDate.setHours(this.recordModel.baseRecord.deviatingTime / 100);
          this.currentDate.setMinutes(this.recordModel.baseRecord.deviatingTime % 100);
        }
      }
    },
    calculateLimits() {
      if (
        this.recordModel.baseRecord &&
        this.recordModel.baseRecord.id &&
        this.limits.length < 2
      ) {
        var gameDate = new Date(this.recordModel.baseRecord.gameReportDeadline);
        var minValue = new Date();
        minValue.setTime(minValue.getTime() + 3 * 24 * 60 * 60 * 1000);
        var maxValue = new Date();
        maxValue.setTime(gameDate.getTime() - 24 * 60 * 60 * 1000);
        maxValue.setHours(15);
        maxValue.setMinutes(0);
        this.limits[0] = minValue;
        this.limits[1] = maxValue;
      }
    },
    addCurrentDate() {
      var gameDate = new Date(this.recordModel.baseRecord.date);
      if (
        this.recordModel.baseRecord.deviatingTime &&
        this.recordModel.baseRecord.deviatingTime > 0
      ) {
        gameDate.setHours(this.recordModel.baseRecord.deviatingTime / 100);
        gameDate.setMinutes(this.recordModel.baseRecord.deviatingTime % 100);
      }
      if (gameDate.getTime() == this.currentDate.getTime()) {
        return;
      }
      var day = this.currentDate.getDate();
      var month = this.currentDate.getMonth() + 1;
      var year = this.currentDate.getFullYear();
      var hours = this.currentDate.getHours();
      var minutes = this.currentDate.getMinutes();
      var dateString =
        (day < 10 ? "0" + day : day) +
        "." +
        (month < 10 ? "0" + month : month) +
        "." +
        year +
        " " +
        (hours < 10 ? "0" + hours : hours) +
        ":" +
        (minutes < 10 ? "0" + minutes : minutes);
      var newDate = {
        value: this.currentDate,
        label: dateString,
      };
      if (
        !this.recordModel.selectedDates.find(
          (d) => d.value.getTime() == newDate.value.getTime()
        )
      ) {
        this.recordModel.selectedDates.push(newDate);
      }
      this.recordModel.selectedDates.sort((d1, d2) => d1.value - d2.value);
      this.setCurrentDate();
    },
    removeSelectedDate(date) {
      this.recordModel.selectedDates = this.recordModel.selectedDates.filter(
        (p) => p != date
      );
    },
    validate() {
      return this.recordModel.selectedDates.length > 0;
    },
  },
};
</script>

<style lang="scss" scoped></style>
