<template>
  <Fieldset
    legend="Nachrichten"
    :toggleable="true"
    :collapsed="fieldSetCollapsed"
    @toggle="toogleView()"
    style="min-width: 300px; margin: 5px 1px"
  >
    <div class="flex" style="max-width: 1200px; margin-bottom: 10px">
      <Dropdown
        v-model="participantIdFilter"
        :options="allTeams"
        :editable="false"
        optionLabel="representation"
        optionValue="id"
        :filter="true"
        style="width: 100%"
        @change="loadConversations"
      />
      <Button
        icon="pi pi-times"
        class="p-button-rounded p-button-info mb2"
        style="margin: 0px 5px; min-width: 20px"
        @click="clearParticipantIdFilter"
      />
    </div>
    <div
      class="flex flex-wrap"
      style="max-width: 1200px; margin-bottom: 10px; border: 1px solid darkgray"
    >
      <ToggleButton
        class="flex align-items-center justify-content-center m-1 border-round"
        :style="'font-size: 14px; padding: 0px;' + (t.active ? 'background:green' : '')"
        v-for="t in conversationTypeModel"
        v-bind:key="t.id"
        v-model="t.active"
        :onLabel="t.label"
        :offLabel="t.label"
        onIcon="pi pi-check"
        offIcon="pi pi-times"
        @change="loadConversations"
      />
    </div>
    <DataTable
      class="p-datatable-sm"
      :value="latestConversations"
      stripedRows
      responsiveLayout="stack"
      breakpoint="650px"
      style="margin-top: 10px"
    >
      <Column header="Letzte Änderung">
        <template #body="slotProps">
          {{ formatDateTimeInfo(slotProps.data.updateTimestamp) }}
        </template>
      </Column>
      <Column field="subject" header="Betreff"></Column>
      <Column field="requestParticipant" header="Ersteller"></Column>
      <Column header="Ersteller gelesen am">
        <template #body="slotProps">
          {{ formatDateTimeInfo(slotProps.data.requestReadTimestamp) }}
        </template>
      </Column>
      <Column field="answerParticipant" header="Angefragter"></Column>
      <Column header="Angefragter gelesen am">
        <template #body="slotProps">
          {{ formatDateTimeInfo(slotProps.data.answerReadTimestamp) }}
        </template>
      </Column>
      <Column header="Antwort">
        <template #body="slotProps">
          <div v-html="formatAnswer(slotProps.data.resultingAnswer)" />
        </template>
      </Column>
    </DataTable>
  </Fieldset>
</template>

<script>
import ServerConnection from "../../services/ServerConnection/ServerConnection";
import FailureHandler from "../../services/ServerConnection/FailureHandler";
import Fieldset from "primevue/fieldset";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ToggleButton from "primevue/togglebutton";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
export default {
  name: "AdminConversationsView",
  components: {
    Button,
    Fieldset,
    DataTable,
    Column,
    ToggleButton,
    Dropdown,
  },
  mounted() {
    this.loadAllTeams();
    this.loadConversations();
  },
  updated() {
    this.loadAllTeams();
    this.loadConversations();
  },
  data() {
    return {
      fieldSetCollapsed: true,
      latestConversations: [],
      allTeams: [],
      participantIdFilter: "",
      conversationTypeModel: [
        { id: "INFORMATION", label: "Information", active: false },
        { id: "QUESTION", label: "Frage", active: false },
        { id: "REQUEST_GAME_SWITCH_HOME_GUEST", label: "Heimrechttausch", active: false },
        { id: "REQUEST_GAME_CHANGE_DATE", label: "Spielverlegung", active: false },
      ],
    };
  },
  methods: {
    toogleView() {
      this.fieldSetCollapsed = !this.fieldSetCollapsed;
    },
    formatDateTimeInfo(date) {
      if (date && date.length > 0) {
        var pattern = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2})/;
        return date.replace(pattern, "$3.$2.$1 $4:$5 Uhr");
      }
      return "";
    },
    formatConversationType(type) {
      if (type) {
        return this.conversationTypeModel
          .filter((t) => t.id == type)
          .map((t) => t.label)
          .join(",");
      }
      return "";
    },
    formatAnswer(answer) {
      if (answer && answer != null && answer.length > 0) {
        return answer;
      }
      return "Keine Antwort";
    },
    loadAllTeams() {
      if (!this.fieldSetCollapsed) {
        ServerConnection.get("admin/allTeams")
          .then((response) => (this.allTeams = response.data))
          .catch((error) => {
            FailureHandler.handleServerFailure(this.$router, error);
          });
      }
    },
    loadConversations() {
      if (!this.fieldSetCollapsed) {
        var url = "admin/latestConversations";

        var conversationTypeFilter = this.conversationTypeModel
          .filter((t) => t.active)
          .map((t) => t.id)
          .join(",");
        if (conversationTypeFilter && conversationTypeFilter.length > 0) {
          url += "?conversationTypeFilter=" + conversationTypeFilter;
          if (this.participantIdFilter && this.participantIdFilter > 0) {
            url += "&participantIdFilter=" + this.participantIdFilter;
          }
        } else if (this.participantIdFilter && this.participantIdFilter > 0) {
          url += "?participantIdFilter=" + this.participantIdFilter;
        }
        ServerConnection.get(url)
          .then((response) => (this.latestConversations = response.data))
          .catch((error) => {
            FailureHandler.handleServerFailure(this.$router, error);
          });
      }
    },
    clearParticipantIdFilter() {
      this.participantIdFilter = "";
      this.loadConversations();
    },
  },
};
</script>

<style lang="scss" scoped></style>
