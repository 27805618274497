<template>
  <div id="rootDiv" v-if="game && game.teamHome && game.teamGuest">
    <div
      v-if="showSideBySideComparsion"
      class="flex align-items-center justify-content-center"
    >
      <Splitter style="height: 100%">
        <SplitterPanel :size="30">
          <Accordion :multiple="true" :activeIndex="[0, 1, 2, 3]">
            <AccordionTab v-for="i in gameMode.numberOfBlocks" v-bind:key="i"
              ><template #header>
                {{ i }}. Block
                <span style="font-size: 14px" v-if="editMode && isBlockInvalid(i)">
                  &nbsp; (unvollständig)
                </span>
              </template>
              <div id="blockDiv">
                <div style="text-align: left; font-size: 18px; margin-top: 5px"></div>
                <div
                  v-for="j in getBlock(i).numberOfGames"
                  v-bind:key="j"
                  style="border: 1px darkgray solid; margin-bottom: 1px"
                >
                  <div id="setDiv" class="grid" style="align-items: center; margin: 0rem">
                    <div class="col-2 flex-1" style="padding: 1px 2px">
                      <div
                        id="playerDiv"
                        class="flex flex-column card-container"
                        style="
                          display: block;
                          padding: 2px;
                          min-width: 60px;
                          background: #ceeeff;
                          margin: 4px 1px;
                        "
                        v-for="k in getBlock(i).numberOfPLayersPerGame"
                        v-bind:key="k"
                      >
                        <Button
                          v-if="editMode"
                          @click="openPlayerDialog(i, j, k, true)"
                          :label="
                            getResultSetForPlayerField(
                              i,
                              j,
                              k,
                              true,
                              'name',
                              'Spieler wählen'
                            )
                          "
                          style="padding: 2px; background-color: #1c80cf"
                        />
                        <div v-else style="margin: 3px 1px">
                          {{ getResultSetForPlayerField(i, j, k, true, "name", "-") }}
                        </div>
                        <Button
                          class="highLightButton"
                          v-if="getResultSetForPlayerField(i, j, k, true, 'id', null) > 0"
                          @click="openHighlightsDialog(i, j, k, true)"
                          :label="formatDetails(i, j, k, true, 'Highlights')"
                          style="padding: 1px; margin-top: 3px"
                        />
                      </div>
                    </div>
                    <div
                      id="resultDiv"
                      class="col-2"
                      style="margin: 0px; padding: 4px 0px"
                    >
                      <Button
                        class="highLightButton"
                        @click="openResultDialog(i, j)"
                        :label="formatResult(i, j)"
                        style="min-width: 15px; padding-left: 2px; padding-right: 2px"
                      />
                    </div>
                    <div class="col-2 flex-1" style="padding: 2px 2px">
                      <div
                        id="playerDiv"
                        class="flex flex-column card-container"
                        style="
                          display: block;
                          padding: 2px;
                          min-width: 60px;
                          background: #ceeeff;
                          margin: 4px 1px;
                        "
                        v-for="k in getBlock(i).numberOfPLayersPerGame"
                        v-bind:key="k"
                      >
                        <Button
                          @click="openPlayerDialog(i, j, k, false)"
                          :label="
                            getResultSetForPlayerField(
                              i,
                              j,
                              k,
                              false,
                              'name',
                              'Spieler wählen'
                            )
                          "
                          style="padding: 2px; background-color: #1c80cf"
                        />
                        <Button
                          class="highLightButton"
                          @click="openHighlightsDialog(i, j, k, false)"
                          :label="formatDetails(i, j, k, false, 'Highlights')"
                          style="padding: 1px; margin-top: 3px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </AccordionTab>
          </Accordion>
        </SplitterPanel>
        <SplitterPanel :size="70">
          <div class="flex-grow-1 flex align-items-center justify-content-center">
            <UploadGameResult
              :gameId="game.id"
              :editMode="false"
              ref="uploadGameResult"
            />
          </div>
        </SplitterPanel>
      </Splitter>
    </div>
    <div
      v-else
      class="align-items-center justify-content-center"
      style="margin-top: 10px"
    >
      <div class="flex align-items-center justify-content-center">
        <div
          v-if="adminMode && editMode"
          class="flex flex-wrap card-container align-items-center justify-content-center"
        >
          <div>
            <div
              class="flex flex-wrap card-container align-items-center justify-content-center"
            >
              <div
                v-for="recordFieldDescription in recordFieldDescriptions"
                v-bind:key="recordFieldDescription.name + ':' + Math.random()"
              >
                <RecordField
                  v-model:record="game"
                  :recordFieldDescription="recordFieldDescription"
                  :editMode="true"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <h4 v-if="adminMode" style="margin: 2px; font-size: 20px">
            <router-link :to="'/recordview/team/' + game.teamHome.id" style="border: 0px">
              {{ game.teamHome.name }}
            </router-link>
            :
            <router-link
              :to="'/recordview/team/' + game.teamGuest.id"
              style="border: 0px"
            >
              {{ game.teamGuest.name }}
            </router-link>
          </h4>
          <h4 v-else style="margin: 2px; font-size: 20px">
            {{ game.teamHome.name }} : {{ game.teamGuest.name }}
          </h4>
          <h4 style="margin: 2px; font-size: 20px">
            {{ game.date }}
          </h4>
        </div>
      </div>
      <div class="card">
        <div class="flex flex-column card-container green-container">
          <div
            v-if="!adminMode || !editMode"
            class="flex align-items-center justify-content-center"
          >
            <h4 style="padding: 2px; margin: 2px; font-size: 20px">
              Ergebnis {{ setResult }}
            </h4>
            <h4 style="padding: 2px; margin: 2px; font-size: 18px">
              (Legs: {{ legResult }})
            </h4>
          </div>
          <div
            v-if="!adminMode || !editMode"
            class="flex flex-column card-container green-container"
          >
            <div class="flex flex-column align-items-center justify-content-center">
              <Message
                style="margin-bottom: 0px"
                :closable="false"
                :severity="messageSeverity"
                ><span style="font-size: 20px">{{ translatedGameStatus }}</span
                ><br /><span
                  v-if="
                    editMode &&
                    translatedGameStatusHint &&
                    translatedGameStatusHint.length != 0
                  "
                  style="font-size: 14px; padding: 0px; margin: 0px"
                  >{{ translatedGameStatusHint }}
                </span></Message
              >
            </div>
          </div>
          <div id="gameDiv" class="flex align-items-center justify-content-center">
            <div class="flex-1" style="max-width: 800px">
              <TabView>
                <TabPanel>
                  <template #header>
                    <span id="tabHeader">{{ resultTabHeader }}</span>
                  </template>
                  <Accordion :multiple="!editMode" :activeIndex="activeIndex">
                    <AccordionTab v-for="i in gameMode.numberOfBlocks" v-bind:key="i"
                      ><template #header>
                        {{ i }}. Block
                        <span
                          style="font-size: 14px"
                          v-if="editMode && isBlockInvalid(i)"
                        >
                          &nbsp; (unvollständig)
                        </span>
                      </template>
                      <div id="blockDiv">
                        <div
                          style="text-align: left; font-size: 18px; margin-top: 5px"
                        ></div>
                        <div
                          v-for="j in getBlock(i).numberOfGames"
                          v-bind:key="j"
                          style="border: 1px darkgray solid; margin-bottom: 1px"
                        >
                          <div
                            v-if="getBlock(i).gameType != 'team'"
                            id="setDiv"
                            class="grid"
                            style="align-items: center; margin: 0rem"
                          >
                            <div class="col-2 flex-1" style="padding: 1px 2px">
                              <div
                                id="playerDiv"
                                class="flex flex-column card-container"
                                style="
                                  display: block;
                                  padding: 2px;
                                  min-width: 60px;
                                  background: #ceeeff;
                                  margin: 4px 1px;
                                "
                                v-for="k in getBlock(i).numberOfPLayersPerGame"
                                v-bind:key="k"
                              >
                                <Button
                                  class="playerButton"
                                  v-if="editMode"
                                  @click="openPlayerDialog(i, j, k, true)"
                                  :label="
                                    getResultSetForPlayerField(
                                      i,
                                      j,
                                      k,
                                      true,
                                      'name',
                                      'Spieler wählen'
                                    )
                                  "
                                  style="padding: 2px; background-color: #1c80cf"
                                />
                                <div v-else style="margin: 3px 1px">
                                  {{
                                    getResultSetForPlayerField(i, j, k, true, "name", "-")
                                  }}
                                </div>
                                <Button
                                  v-if="
                                    editMode &&
                                    getResultSetForPlayerField(
                                      i,
                                      j,
                                      k,
                                      true,
                                      'id',
                                      null
                                    ) > 0
                                  "
                                  class="highLightButton"
                                  @click="openHighlightsDialog(i, j, k, true)"
                                  :label="formatDetails(i, j, k, true, 'Highlights')"
                                  style="padding: 2px; margin-top: 10px"
                                />
                                <div v-else style="font-size: 12px">
                                  {{ formatDetails(i, j, k, true, "") }}
                                </div>
                              </div>
                            </div>
                            <div
                              id="resultDiv"
                              class="col-2"
                              style="margin: 0px; padding: 4px 0px"
                            >
                              <Button
                                class="highLightButton"
                                v-if="editMode"
                                @click="openResultDialog(i, j)"
                                :label="formatResult(i, j)"
                                style="
                                  min-width: 15px;
                                  padding-left: 2px;
                                  padding-right: 2px;
                                "
                              />
                              <h4 v-else class="highLightButton" style="margin: 1px 2px">
                                {{ getGameSet(i, j).pointsHome }} :
                                {{ getGameSet(i, j).pointsGuest }}
                              </h4>
                            </div>
                            <div class="col-2 flex-1" style="padding: 2px 2px">
                              <div
                                id="playerDiv"
                                class="flex flex-column card-container"
                                style="
                                  display: block;
                                  padding: 2px;
                                  min-width: 60px;
                                  background: #ceeeff;
                                  margin: 4px 1px;
                                "
                                v-for="k in getBlock(i).numberOfPLayersPerGame"
                                v-bind:key="k"
                              >
                                <Button
                                  class="playerButton"
                                  v-if="editMode"
                                  @click="openPlayerDialog(i, j, k, false)"
                                  :label="
                                    getResultSetForPlayerField(
                                      i,
                                      j,
                                      k,
                                      false,
                                      'name',
                                      'Spieler wählen'
                                    )
                                  "
                                  style="padding: 2px; background-color: #1c80cf"
                                />
                                <div v-else style="margin: 3px 1px">
                                  {{
                                    getResultSetForPlayerField(
                                      i,
                                      j,
                                      k,
                                      false,
                                      "name",
                                      "-"
                                    )
                                  }}
                                </div>
                                <Button
                                  v-if="
                                    editMode &&
                                    getResultSetForPlayerField(
                                      i,
                                      j,
                                      k,
                                      false,
                                      'id',
                                      null
                                    ) > 0
                                  "
                                  class="highLightButton"
                                  @click="openHighlightsDialog(i, j, k, false)"
                                  :label="formatDetails(i, j, k, false, 'Highlights')"
                                  style="padding: 2px; margin-top: 10px"
                                />
                                <div v-else style="font-size: 12px">
                                  {{ formatDetails(i, j, k, false, "") }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            v-else
                            id="setDiv"
                            class="grid"
                            style="align-items: center; margin: 0rem"
                          >
                            <div class="col-2 flex-1" style="padding: 1px 2px">
                              <div
                                id="playerDiv"
                                class="flex flex-column card-container"
                                style="
                                  display: block;
                                  padding: 2px;
                                  min-width: 60px;
                                  background: #ceeeff;
                                  margin: 4px 1px;
                                "
                              >
                                <span>Viererteam</span>
                              </div>
                            </div>

                            <div
                              id="resultDiv"
                              class="col-2"
                              style="margin: 0px; padding: 4px 0px"
                            >
                              <Button
                                class="highLightButton"
                                v-if="editMode"
                                @click="openResultDialog(i, j)"
                                :label="formatResult(i, j)"
                                style="
                                  min-width: 15px;
                                  padding-left: 2px;
                                  padding-right: 2px;
                                "
                              />
                              <h4 v-else class="highLightButton" style="margin: 1px 2px">
                                {{ getGameSet(i, j).pointsHome }} :
                                {{ getGameSet(i, j).pointsGuest }}
                              </h4>
                            </div>
                            <div class="col-2 flex-1" style="padding: 1px 2px">
                              <div
                                id="playerDiv"
                                class="flex flex-column card-container"
                                style="
                                  display: block;
                                  padding: 2px;
                                  min-width: 60px;
                                  background: #ceeeff;
                                  margin: 4px 1px;
                                "
                              >
                                <span>Viererteam</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </AccordionTab>
                  </Accordion>
                </TabPanel>
                <TabPanel
                  :header="imageTabHeader"
                  v-if="game && game.id && (editMode || gameImageExists)"
                >
                  <div style="margin-top: 10px">
                    <UploadGameResult
                      :gameId="game.id"
                      :editMode="editMode"
                      ref="uploadGameResult"
                      @imageChanged="imageChanged()"
                    />
                  </div>
                </TabPanel>
                <TabPanel header="Bemerkungen" v-if="showComments()">
                  <div class="flex flex-column card-container">
                    <h4 style="margin-bottom: 12px">Bemerkungen</h4>
                    <Button
                      label="Bemerkung hinzufügen"
                      @click="openCommentDialog(false)"
                      v-if="
                        (editMode && adminMode) ||
                        (allowComments() && (isHomeTeam() || isGuestTeam()))
                      "
                      style="margin-bottom: 4px"
                    />
                    <Button
                      label="Bemerkungen editieren"
                      @click="openCommentDialog(true)"
                      v-if="editMode && adminMode"
                      style="margin-bottom: 4px"
                    />
                    <Textarea
                      v-model="game.notes"
                      :autoResize="false"
                      style="width: 100%; height: 100vh"
                      disabled
                    />
                  </div>
                </TabPanel>
                <TabPanel header="Spielleiter" v-if="adminMode">
                  <div class="flex flex-column card-container">
                    <h4 style="margin-bottom: 12px">Spielleiter-Bemerkungen</h4>
                    <Textarea
                      v-if="editMode"
                      v-model="game.gameManagerNotes"
                      :autoResize="false"
                      style="width: 100%; height: 100vh"
                    />
                    <Textarea
                      v-else
                      v-model="game.gameManagerNotes"
                      :autoResize="false"
                      style="width: 100%; height: 100vh"
                      disabled
                    />
                  </div>
                </TabPanel>
              </TabView>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Dialog v-model:visible="displayResultDialog" style="width: 300px" :closable="false">
      <template #header>
        <div style="font-size: 20px; text-align: center; width: 100%">Spielergebnis</div>
      </template>

      <div class="card">
        <div class="flex flex-column card-container justify-content-center">
          <div
            class="flex align-items-center justify-content-center"
            style="margin: 20px; font-size: 22px"
          >
            {{ dialogGameSet.pointsHome }} : {{ dialogGameSet.pointsGuest }}
          </div>
          <div v-if="resultMode == 'slider'">
            <Slider
              :min="0"
              :max="6"
              v-model="gameResultSliderValue"
              @change="gameResultChangedSlider"
            />
          </div>
          <div
            v-if="resultMode == 'buttons'"
            class="flex flex-column card-container justify-content-center"
          >
            <div style="text-align: center">
              Gewinner:
              <SelectButton
                :options="['Heim', 'Gast']"
                v-model="gameResultButtonValue.winner"
                @change="gameResultChangedButtons"
              />
            </div>
            <div style="margin-top: 15px; text-align: center">
              Punkte Verlierer:
              <SelectButton
                :options="gameResultButtonValue.pointsOpponentValues"
                v-model="gameResultButtonValue.pointsOpponent"
                @change="gameResultChangedButtons"
                style="font-size: 20px"
              />
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <div class="flex justify-content-between card-container" style="width: 100%">
          <Button
            label="Löschen"
            @click="deleteResult()"
            style="margin: 2px !important"
          />
          <Button
            label="Fertig"
            @click="closeResultDialog()"
            style="margin: 2px !important"
          />
        </div>
      </template>
    </Dialog>
    <Dialog v-model:visible="displayPlayerDialog" style="width: 350px" :closable="false">
      <template #header>
        <div style="font-size: 20px; text-align: center; width: 100%">Spielerauswahl</div>
      </template>

      <div class="card">
        <div class="flex flex-column card-container justify-content-center">
          <div
            class="flex align-items-center justify-content-center"
            style="margin: 20px; font-size: 20px"
          >
            <Dropdown
              v-model="selectedDialogPlayerId"
              :options="dialogPlayers"
              :editable="false"
              optionLabel="representation"
              optionValue="id"
              :filter="dialogPlayers.length >= 10"
              style="width: 100%"
              @change="closePlayerDialog(true)"
            />
          </div>
        </div>
      </div>
      <template #footer>
        <div
          class="flex justify-content-between flex-wrap card-container"
          style="width: 100%"
        >
          <Button
            label="Abbrechen"
            @click="closePlayerDialog(false)"
            style="margin: 2px !important"
          />
          <Button
            label="Fertig"
            @click="closePlayerDialog(true)"
            style="margin: 2px !important"
          />
        </div>
      </template>
    </Dialog>
    <Dialog
      v-model:visible="displayHighlightsDialog"
      style="width: 350px"
      :closable="false"
    >
      <template #header>
        <div style="font-size: 20px; text-align: center; width: 100%">Highlights</div>
      </template>

      <div class="card">
        <div
          v-if="getBlock(dialogGameSet.blockNumber).highScores"
          class="flex flex-column card-container justify-content-center"
        >
          <h4 style="margin-top: 2px; margin-bottom: 2px">HighScores (>= 171)</h4>
          <div v-if="dialogHighLightsModel.highScores">
            <div
              v-for="hs in dialogHighLightsModel.highScores"
              v-bind:key="hs"
              style="margin: 5px"
            >
              {{ hs.key }} : {{ hs.value }}
              <Button
                icon="pi pi-plus"
                style="margin-left: 10px; padding: 2px; width: 20px"
                @click="hs.value++"
              />
              <Button
                v-if="hs.value && hs.value > 0"
                icon="pi pi-minus"
                style="margin-left: 10px; padding: 2px; width: 20px"
                @click="hs.value--"
              />
            </div>
            <InputText v-model="highScoreText" />
            <Button
              icon="pi pi-plus"
              style="margin-top: 10px; margin-left: 10px; width: 25px"
              @click="addHighScore()"
            />
          </div>
        </div>
        <div
          v-if="getBlock(dialogGameSet.blockNumber).highFinishes"
          class="flex flex-column card-container justify-content-center"
        >
          <h4 style="margin-top: 10px; margin-bottom: 2px">HighFinishes (>= 100)</h4>
          <div v-if="dialogHighLightsModel.highFinishes">
            <div
              v-for="hf in dialogHighLightsModel.highFinishes"
              v-bind:key="hf"
              style="margin: 5px"
            >
              {{ hf.key }} : {{ hf.value }}
              <Button
                icon="pi pi-plus"
                style="margin-left: 10px; padding: 2px; width: 20px"
                @click="hf.value++"
              />
              <Button
                v-if="hf.value && hf.value > 0"
                icon="pi pi-minus"
                style="margin-left: 10px; padding: 2px; width: 20px"
                @click="hf.value--"
              />
            </div>

            <InputText v-model="highFinishText" />
            <Button
              icon="pi pi-plus"
              style="margin-top: 10px; margin-left: 10px; width: 25px"
              @click="addHighFinish()"
            />
          </div>
        </div>
        <div
          v-if="getBlock(dialogGameSet.blockNumber).shortLegs"
          class="flex flex-column card-container justify-content-center"
        >
          <h4 style="margin-top: 10px; margin-bottom: 2px">ShortLegs (&#60;= 18)</h4>
          <div v-if="dialogHighLightsModel.shortLegs">
            <div
              v-for="sl in dialogHighLightsModel.shortLegs"
              v-bind:key="sl"
              style="margin: 5px"
            >
              {{ sl.key }} : {{ sl.value }}
              <Button
                icon="pi pi-plus"
                style="margin-left: 10px; padding: 2px; width: 20px"
                @click="sl.value++"
              />
              <Button
                v-if="sl.value && sl.value > 0"
                icon="pi pi-minus"
                style="margin-left: 10px; padding: 2px; width: 20px"
                @click="sl.value--"
              />
            </div>

            <InputText v-model="shortLegText" />
            <Button
              icon="pi pi-plus"
              style="margin-top: 10px; margin-left: 10px; width: 25px"
              @click="addShortLeg()"
            />
          </div>
        </div>
      </div>
      <template #footer>
        <div class="flex justify-content-between card-container" style="width: 100%">
          <Button
            label="Abbrechen"
            @click="closeHighlightsDialog(false)"
            style="margin: 2px !important"
          />
          <Button
            label="Fertig"
            @click="closeHighlightsDialog(true)"
            style="margin: 2px !important"
          />
        </div>
      </template>
    </Dialog>
    <Dialog v-model:visible="displayFinishDialog" :closable="false">
      <template #header>
        <h3>Spielbericht abschließen?</h3>
      </template>
      <span>{{ finishedDialogText }}</span>
      <span><br />Danach sind keine Änderungen mehr möglich.</span>
      <template #footer>
        <Button
          label="Abschließen"
          icon="pi pi-times"
          class="p-button-text"
          @click="executeFinish"
        />
        <Button label="Abbrechen" icon="pi pi-check" @click="cancelFinish" />
      </template>
    </Dialog>
    <Dialog
      v-model:visible="displayCommentDialog"
      :closable="false"
      :style="{ width: '50vw' }"
      contentStyle="padding: 2px 5px"
    >
      <template #header>
        <h3 style="margin: -5px">Bemerkung hinzufügen</h3>
      </template>
      <Textarea v-model="newComment" :autoResize="true" rows="12" style="width: 100%" />
      <template #footer>
        <div class="flex justify-content-between card-container" style="width: 100%">
          <Button label="Eintragen" @click="addComment" style="margin: 2px !important" />
          <Button
            label="Abbrechen"
            @click="cancelComment"
            style="margin: 2px !important"
          />
        </div>
      </template>
    </Dialog>
    <Dialog
      v-model:visible="displayGameReportErrorDialog"
      :closable="false"
      :style="{ width: '50vw' }"
      contentStyle="padding: 2px 5px"
    >
      <template #header>
        <h3 style="margin: -5px">
          Spielleiter über einen Fehler im Spielbericht informieren
        </h3>
      </template>
      <span style="margin-bottom: 4px"
        >Bitte den/die Fehler im Spielbericht kurz und verständlich erklären, z.B.
        1.Block, 2. Spiel fehlt der 180er bei Gast</span
      >
      <Textarea
        v-model="gameReportError"
        :autoResize="true"
        rows="12"
        style="width: 100%"
      />
      <template #footer>
        <div class="flex justify-content-between card-container" style="width: 100%">
          <Button
            label="Eintragen"
            @click="addGameReportError"
            style="margin: 2px !important"
          />
          <Button
            label="Abbrechen"
            @click="cancelGameReportError"
            style="margin: 2px !important"
          />
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import ServerConnection from "../../../services/ServerConnection/ServerConnection";
import FailureHandler from "../../../services/ServerConnection/FailureHandler";
import { useMenuService } from "../../../services/MenuService/UseMenuService";
import { useWizardService } from "../../../services/WizardService/UseWizardService";
import { useSettingsService } from "../../../services/SettingsService/UseSettingsService";
import Dropdown from "primevue/dropdown";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import SelectButton from "primevue/selectbutton";
import Slider from "primevue/slider";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import InputText from "primevue/inputtext";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Textarea from "primevue/textarea";
import Splitter from "primevue/splitter";
import SplitterPanel from "primevue/splitterpanel";
import UploadGameResult from "./UploadGameResult.vue";
import Message from "primevue/message";
import { gameFieldDescriptions } from "../descriptions/game";
import RecordField from "../RecordField";

const noPlayerHome = {
  id: -1,
  name: "-",
  representation: "------ FREILOS ------",
};
const noPlayerGuest = {
  id: -2,
  name: "-",
  representation: "------ FREILOS ------",
};
export default {
  name: "GameView",
  components: {
    Dropdown,
    Dialog,
    Button,
    SelectButton,
    Slider,
    Accordion,
    AccordionTab,
    InputText,
    TabView,
    TabPanel,
    Textarea,
    Splitter,
    SplitterPanel,
    Message,
    UploadGameResult,
    RecordField,
  },
  data() {
    return {
      menuService: useMenuService(),
      wizardService: useWizardService(),
      settingsService: useSettingsService(),
      game: {},
      oldGameId: {},
      gameMode: {},
      homePlayers: [],
      guestPlayers: [],
      displayResultDialog: false,
      displayPlayerDialog: false,
      displayHighlightsDialog: false,
      displayFinishDialog: false,
      displayCommentDialog: false,
      displayGameReportErrorDialog: false,
      newComment: "",
      gameReportError: "",
      editExistingComment: false,
      showSideBySideComparsion: false,
      dialogGameSet: null,
      dialogSetResult: null,
      dialogPlayers: {},
      dialogHighLightsModel: {},
      selectedDialogPlayerId: {},
      highScoreText: "",
      highFinishText: "",
      shortLegText: "",
      gameResultSliderValue: 0,
      gameResultButtonValue: {
        winner: null,
        pointsOpponent: 0,
      },
      editMode: this.mode && this.mode == "edit",
      editModeAllowed: false,
      activeIndex: this.mode && this.mode == "edit" ? 0 : [0, 1, 2, 3],
      setResult: "0:0",
      legResult: "0:0",
      mannschaftIds: [],
      gameImageExistsOnServer: false,
      finishedDialogText: "",
      resultMode: "slider",
    };
  },
  props: {
    gameId: String,
    mode: String,
  },
  computed: {
    translatedGameStatus() {
      if (this.game && this.game.gameStatus) {
        switch (this.game.gameStatus) {
          case "ToPlay":
            return "Zu spielen";
          case "BeingPlayed":
            return "Wird gerade gespielt";
          case "WaitingForResults":
            return "Warten auf Ergebnisse";
          case "WaitingForImage":
            return "Warten auf Ergebnis-Bild";
          case "WaitingForHomeApproval":
            return "Kann abgeschlossen werden";
          case "InDiscussion":
            return "In Abstimmung";
          case "WaitingForGuestApproval":
            return "In Bestätigung durch Gast";
          case "WaitingForGameManagerApproval":
            return "In Kontrolle durch Spielleiter";
          case "Played":
            return "Gespielt";
          case "PostPoned":
            return "Verlegt";
          case "Unrated":
            return "Keine Wertung";
          case "ChangeRequested":
            return "Änderung beim Gegner angefragt";
        }
      }
      return "";
    },
    messageSeverity() {
      if (this.game && this.game.gameStatus) {
        switch (this.game.gameStatus) {
          case "WaitingForResults":
          case "WaitingForImage":
          case "WaitingForHomeApproval":
          case "InDiscussion":
          case "ChangeRequested":
            return "warn";
          case "WaitingForGuestApproval":
          case "WaitingForGameManagerApproval":
          case "Played":
            return "success";
          case "PostPoned":
          case "ToPlay":
          case "BeingPlayed":
          case "Unrated":
            return "info";
        }
      }
      return null;
    },
    translatedGameStatusHint() {
      if (this.game && this.game.gameStatus) {
        switch (this.game.gameStatus) {
          case "WaitingForResults":
            return "Es fehlen noch Ergebnisse, ein oder mehrer Blöcke sind unvollständig.";
          case "WaitingForImage":
            return "Es wurde noch kein Bild des Spielberichts hochgeladen.";
          case "WaitingForHomeApproval":
            return "Die Eingabe des Spielberichts muss noch über den oberen Haken-Button abgeschlossen werden!";
          case "InDiscussion":
            return "Der Spielbericht enthält Unstimmigkeiten und wird durch den Spielleiter geprüft.";
          case "WaitingForGuestApproval":
            return "Die Abgabe des Spielberichts ist abgeschlossen, der Gast kann den Spielbericht kontrollieren.";
          case "WaitingForGameManagerApproval":
            return "Die Abgabe des Spielberichts ist abgeschlossen und wird durch den Spielleiter kontrolliert.";
          case "Played":
            return "Die Abgabe des Spielberichts ist abgeschlossen.";
          case "PostPoned":
            return "Das Spiel wurde verlegt";
        }
      }
      return null;
    },
    recordFieldDescriptions() {
      return gameFieldDescriptions.filter((gfd) => !gfd.name.startsWith("note"));
    },
    adminMode() {
      var userItem = localStorage.getItem("user");
      var user = JSON.parse(userItem);

      if (!user || !user.roles) {
        return false;
      }
      if (user.roles.includes("admin")) {
        return true;
      }
      if (this.isCupGame()) {
        return user.roles.includes("cupAdmin");
      }
      return false;
    },
    gameImageExists() {
      return (
        this.gameImageExistsOnServer ||
        (this.$refs.uploadGameResult && this.$refs.uploadGameResult.imageExists())
      );
    },
    imageTabHeader() {
      return this.gameImageExists ? "Bild" : "Bild *";
    },
    resultTabHeader() {
      return this.gameIsComplete() ? "Ergebnis" : "Ergebnis *";
    },
  },
  created() {
    this.loadData();
    this.updateMainMenu();
  },
  updated() {
    this.loadData();
    this.updateMainMenu();
  },
  methods: {
    imageChanged() {
      if (!this.gameImageExistsOnServer && this.$refs.uploadGameResult) {
        this.gameImageExistsOnServer = this.$refs.uploadGameResult.imageExists();
        this.saveGame();
        this.updateMainMenu();
      }
    },
    checkEditMode() {
      if (!this.adminMode && this.editMode && this.game && this.game.teamHome) {
        ServerConnection.get("teamadmin/mannschaften")
          .then((response) => response.data)
          .then((data) => {
            this.editModeAllowed =
              data.filter((team) => {
                return this.game.teamHome.id == team.id;
              }).length > 0 && this.statusAllowsEditing();
            if (!this.editModeAllowed) {
              this.editMode = false;
              this.activeIndex = [0, 1, 2, 3];
            } else {
              this.loadPlayers();
            }
            this.updateMainMenu();
          })
          .catch((error) => {
            FailureHandler.handleServerFailure(this.$router, error);
          });
      } else {
        this.editModeAllowed = this.adminMode;
        if (this.editModeAllowed) {
          this.loadPlayers();
          this.updateMainMenu();
        }
      }
    },
    statusAllowsEditing() {
      if (this.game && this.game.gameStatus) {
        switch (this.game.gameStatus) {
          case "ToPlay":
          case "BeingPlayed":
          case "WaitingForResults":
          case "WaitingForImage":
          case "WaitingForHomeApproval":
          case "PostPoned":
            return true;
          default:
            return false;
        }
      }
      return true;
    },
    updateMainMenu() {
      var menuItems = [];
      var sidebarItems = [];
      if (!this.editMode && this.adminMode) {
        sidebarItems.push({
          label: "Zeige Historie",
          icon: "pi pi-history",
          command: () => {
            this.$router.push("/history/game/" + this.gameId);
          },
        });
        sidebarItems.push({
          label: "Sieger wählen",
          icon: "pi pi-verified",
          command: () => {
            this.wizardService.navigateToWizard(
              this.$router,
              "setWinner",
              {
                baseRecord: this.game,
              },
              this.$route.fullPath
            );
          },
        });
        sidebarItems.push({
          label: "Heimrecht ändern",
          icon: "pi pi-code",
          command: () => {
            this.wizardService.navigateToWizard(
              this.$router,
              "switchHomeGuest",
              {
                baseRecord: this.game,
              },
              this.$route.fullPath
            );
          },
        });
        menuItems.push("sidebar");
      }
      if (!this.editMode && this.editModeAllowed) {
        menuItems.push({
          name: "edit",
          command: () => {
            this.editMode = true;
            if (!this.activeIndex || this.activeIndex.length > 1) {
              this.activeIndex = 0;
            }
            this.loadPlayers();
            this.updateMainMenu();
          },
        });
      }

      if (this.adminMode && this.editMode) {
        menuItems.push({
          name: "save",
          command: () => {
            this.updateResults();
            this.saveGame();
            this.editMode = false;
            this.showSideBySideComparsion = false;
            this.activeIndex = [0, 1, 2, 3];
            this.updateMainMenu();
          },
        });
      }
      if (
        !this.adminMode &&
        this.editMode &&
        this.gameIsComplete() &&
        this.gameImageExists
      ) {
        menuItems.push({
          name: "finish",
          command: () => {
            this.finishedDialogText =
              "Soll die Eingabe des Spielberichts abgeschlossen werden?";
            this.displayFinishDialog = true;
          },
        });
      }
      if (!this.adminMode && this.gameIsComplete() && this.isWaitingForGuestApproval()) {
        menuItems.push({
          name: "finish",
          label: "Spielbericht abgenommen",
          tooltip: "Der Spielbericht ist abgenommen",
          command: () => {
            this.finishedDialogText = "Soll der Spielbericht abgeschlossen werden?";
            this.displayFinishDialog = true;
          },
        });
        menuItems.push({
          name: "hint",
          label: "Spielbericht fehlerhaft",
          tooltip: "Der Spielbericht ist fehlerhaft",
          command: () => {
            this.displayGameReportErrorDialog = true;
          },
        });
      }
      if (this.adminMode && !this.editMode && this.isShowSideBySideAllowed()) {
        menuItems.push({
          name: "compare",
          command: () => {
            this.showSideBySideComparsion = true;
            this.editMode = true;
            this.loadPlayers();
          },
        });
      }
      if (this.editMode && this.adminMode) {
        menuItems.push({
          name: "cancel",
          command: () => {
            this.editMode = false;
            this.showSideBySideComparsion = false;
            this.activeIndex = [0, 1, 2, 3];
            if (this.adminMode) {
              this.oldGameId = 0;
              this.loadData();
            }
            this.updateMainMenu();
          },
        });
      } else {
        menuItems.push({
          name: "return",
          command: () => {
            if (window.history.length > 1) {
              this.$router.go(-1);
            } else {
              this.$router.push("/");
            }
          },
        });
      }
      this.menuService.setSidebarItems(sidebarItems);
      this.menuService.setMainMenuItemNames(menuItems);
    },
    loadData() {
      if (this.settingsService) {
        this.settingsService
          .getSettings()
          .then((settings) => (this.resultMode = settings.resultMode ?? "slider"));
      }
      if (!this.oldGameId || this.oldGameId != this.gameId) {
        this.game = {};
        this.homePlayers = [];
        this.guestPlayers = [];
        this.oldGameId = this.gameId;
        ServerConnection.get("data/game/" + this.gameId)
          .then((response) => response.data)
          .then((data) => {
            this.game = data;
            if (
              !this.adminMode &&
              (!this.mannschaftIds || this.mannschaftIds.length == 0)
            ) {
              ServerConnection.get("teamadmin/mannschaften/ids")
                .then((response) => response.data)
                .then((data) => {
                  this.mannschaftIds = data;
                  this.updateMainMenu();
                })
                .catch((error) => {
                  FailureHandler.handleServerFailure(this.$router, error);
                });
            }
            this.checkEditMode();
            this.updateResults();
          })
          .catch((error) => {
            FailureHandler.handleServerFailure(this.$router, error);
          });
        ServerConnection.get("file/game/" + +this.gameId + "/image/exists")
          .then((response) => {
            this.gameImageExistsOnServer = response.status == 200;
            this.updateMainMenu();
          })
          .catch((error) => {
            FailureHandler.handleServerFailure(this.$router, error);
          });

        ServerConnection.get("data/game/" + this.gameId + "/gameMode")
          .then((response) => response.data)
          .then((data) => {
            this.gameMode = data;
            this.updateMainMenu();
          })
          .catch((error) => {
            FailureHandler.handleServerFailure(this.$router, error);
          });
      }
    },
    loadPlayers() {
      if (
        this.editMode &&
        this.editModeAllowed &&
        (!this.homePlayers ||
          this.homePlayers.length < 1 ||
          !this.guestPlayers ||
          this.guestPlayers.length < 1)
      ) {
        ServerConnection.get("data/game/" + this.gameId + "/players/home")
          .then((response) => response.data)
          .then((data) => {
            this.homePlayers = data;
            this.homePlayers.push(noPlayerHome);
          })
          .catch((error) => {
            FailureHandler.handleServerFailure(this.$router, error);
          });
        ServerConnection.get("data/game/" + this.gameId + "/players/guest")
          .then((response) => response.data)
          .then((data) => {
            this.guestPlayers = data;
            this.guestPlayers.push(noPlayerGuest);
          })
          .catch((error) => {
            FailureHandler.handleServerFailure(this.$router, error);
          });
      }
    },
    isHomeTeam() {
      return (
        this.game &&
        this.game.teamHome &&
        this.mannschaftIds &&
        this.mannschaftIds.length > 0 &&
        this.mannschaftIds.find((id) => id == this.game.teamHome.id)
      );
    },
    isGuestTeam() {
      return (
        this.game &&
        this.game.teamGuest &&
        this.mannschaftIds &&
        this.mannschaftIds.length > 0 &&
        this.mannschaftIds.find((id) => id == this.game.teamGuest.id)
      );
    },
    isWaitingForGuestApproval() {
      return this.isGuestTeam() && this.game.gameStatus == "WaitingForGuestApproval";
    },
    gameIsComplete() {
      if (
        !this.gameMode ||
        !this.game ||
        !this.game.gameSets ||
        this.game.gameSets.length < 1
      ) {
        return false;
      }
      if (this.isCupGame()) {
        return this.game.pointsHome >= 7 || this.game.pointsGuest >= 7;
      }
      var expectedGameSets = 0;
      for (let i = 1; i <= this.gameMode.numberOfBlocks; i++) {
        var block = this.getBlock(i);
        expectedGameSets += block.numberOfGames;
      }
      if (this.game.gameSets.length != expectedGameSets) {
        return false;
      }
      if (
        this.game.gameSets.filter((gameSet) => !this.isGameSetValid(gameSet)).length > 0
      ) {
        return false;
      }
      return true;
    },
    handleNoteChanged() {
      if (this.editMode && !this.adminMode) {
        this.saveGame();
      }
    },
    saveGame() {
      this.game.gameSets = this.game.gameSets.filter((gameSet) =>
        this.removeInvalidSetResults(gameSet)
      );
      if (this.adminMode || this.game.gameSets.length > 0) {
        ServerConnection.put("data/game/" + this.gameId, JSON.stringify(this.game))
          .then(() => {
            ServerConnection.get("data/game/" + this.gameId)
              .then((response) => response.data)
              .then((data) => {
                this.game = data;
                this.oldGameId = this.gameId;
                this.updateResults();
              })
              .catch((error) => {
                FailureHandler.handleServerFailure(this.$router, error);
              });
          })
          .catch((error) => {
            FailureHandler.handleServerFailure(this.$router, error);
          });
      }
    },
    getBlock(blockNumber) {
      return this.gameMode["block" + blockNumber];
    },
    getGameSet(blockNumber, position) {
      if (!this.game.gameSets) {
        this.game.gameSets = [];
      }
      var gameSet = this.game.gameSets.find(
        (gs) => gs.blockNumber == blockNumber && gs.position == position
      );
      if (!gameSet) {
        gameSet = {
          internal_Id: Math.floor(Math.random() * 100000),
          blockNumber: blockNumber,
          position: position,
          pointsHome: 0,
          pointsGuest: 0,
          setResultForHomePlayers: [],
          setResultForGuestPlayers: [],
          setStatus: "ToPlay",
        };
        this.game.gameSets.push(gameSet);
      }
      if (!gameSet.pointsHome || gameSet.pointsHome == null) {
        gameSet.pointsHome = 0;
      }
      if (!gameSet.pointsGuest || gameSet.pointsGuest == null) {
        gameSet.pointsGuest = 0;
      }
      return gameSet;
    },
    getResultSetForPlayer(blockNumber, position, playerIndex, isHomePlayer) {
      var gameSet = this.getGameSet(blockNumber, position);
      var setResultForPlayers = isHomePlayer
        ? gameSet.setResultForHomePlayers
        : gameSet.setResultForGuestPlayers;
      var setResultForPlayer;
      if (setResultForPlayers) {
        setResultForPlayer = setResultForPlayers.find(
          (set) => set.position == playerIndex
        );
      } else {
        setResultForPlayers = [];
        if (isHomePlayer) {
          gameSet.setResultForHomePlayers = setResultForPlayers;
        } else {
          gameSet.setResultForGuestPlayers = setResultForPlayers;
        }
      }

      if (!setResultForPlayer) {
        setResultForPlayer = {
          position: playerIndex,
          player: {},
        };
        setResultForPlayers.push(setResultForPlayer);
      }
      if (!setResultForPlayer.player || !setResultForPlayer.player.id) {
        if (isHomePlayer && gameSet.setStatus == "WinForGuest") {
          setResultForPlayer.player = noPlayerHome;
        }
        if (!isHomePlayer && gameSet.setStatus == "WinForHome") {
          setResultForPlayer.player = noPlayerGuest;
        }
      }
      return setResultForPlayer;
    },
    getResultSetForPlayerField(
      blockNumber,
      position,
      playerIndex,
      isHomePlayer,
      fieldName,
      defaultValue
    ) {
      var setResultForPlayer = this.getResultSetForPlayer(
        blockNumber,
        position,
        playerIndex,
        isHomePlayer
      );
      var value =
        setResultForPlayer &&
        setResultForPlayer.player &&
        fieldName in setResultForPlayer.player
          ? setResultForPlayer.player[fieldName]
          : null;
      if (!value && fieldName == "name") {
        value = defaultValue;
      }
      return value;
    },
    formatResult(blockNumber, position) {
      var gameSet = this.getGameSet(blockNumber, position);
      if (gameSet.pointsHome == 0 && gameSet.pointsGuest == 0) {
        return "- : -";
      }
      return gameSet.pointsHome + " : " + gameSet.pointsGuest;
    },
    formatDetails(blockNumber, position, playerIndex, isHomePlayer, defaultValue) {
      var setResultForPlayer = this.getResultSetForPlayer(
        blockNumber,
        position,
        playerIndex,
        isHomePlayer
      );

      if (setResultForPlayer && setResultForPlayer.jsonWithDetails) {
        var details =
          typeof setResultForPlayer.jsonWithDetails === "string"
            ? JSON.parse(setResultForPlayer.jsonWithDetails)
            : setResultForPlayer.jsonWithDetails;
        setResultForPlayer.jsonWithDetails = details;
        var hsString = details.highScores
          ? details.highScores
              .filter((entry) => entry && entry.value && entry.value > 0)
              .map(
                (entry) => entry.key + (entry.value > 1 ? " (" + entry.value + ")" : "")
              )
              .reduce(
                (previousValue, currentValue) =>
                  previousValue.length > 0
                    ? previousValue + ", " + currentValue
                    : currentValue,
                ""
              )
          : "";
        var hfString = details.highFinishes
          ? details.highFinishes
              .filter((entry) => entry && entry.value && entry.value > 0)
              .map(
                (entry) => entry.key + (entry.value > 1 ? " (" + entry.value + ")" : "")
              )
              .reduce(
                (previousValue, currentValue) =>
                  previousValue.length > 0
                    ? previousValue + ", " + currentValue
                    : currentValue,
                ""
              )
          : "";
        var slString = details.shortLegs
          ? details.shortLegs
              .filter((entry) => entry && entry.value)
              .map(
                (entry) => entry.key + (entry.value > 1 ? " (" + entry.value + ")" : "")
              )
              .reduce(
                (previousValue, currentValue) =>
                  previousValue.length > 0
                    ? previousValue + ", " + currentValue
                    : currentValue,
                ""
              )
          : "";
        var detailString = "";
        if (hsString && hsString.length > 0) {
          detailString += "HS: " + hsString;
        }
        if (hfString && hfString.length > 0) {
          if (detailString.length > 0) {
            detailString += ", ";
          }
          detailString += "HF: " + hfString;
        }
        if (slString && slString.length > 0) {
          if (detailString.length > 0) {
            detailString += ", ";
          }
          detailString += "SL: " + slString;
        }
        if (detailString.length < 1) {
          return defaultValue;
        }
        return detailString;
      }
      return defaultValue;
    },
    openResultDialog(blockNumber, position) {
      var currentGameSet = this.getGameSet(blockNumber, position);

      if (
        currentGameSet.setStatus == "WinForHome" ||
        currentGameSet.setStatus == "WinForGuest"
      ) {
        return;
      }

      var maxPoints = Math.round(this.gameMode["block" + blockNumber].bestOf / 2);

      this.dialogGameSet = currentGameSet;
      this.displayResultDialog = true;
      this.gameResultSliderValue =
        maxPoints + this.dialogGameSet.pointsGuest - this.dialogGameSet.pointsHome;
      var pointsOpponentValues = [];
      for (var i = 0; i < maxPoints; i++) {
        pointsOpponentValues.push(i);
      }
      this.gameResultButtonValue.pointsOpponentValues = pointsOpponentValues;

      if (this.dialogGameSet.pointsHome > 0 || this.dialogGameSet.pointsGuest > 0) {
        if (this.dialogGameSet.pointsHome > this.dialogGameSet.pointsGuest) {
          this.gameResultButtonValue.winner = "Heim";
          this.gameResultButtonValue.pointsOpponent = this.dialogGameSet.pointsGuest;
        } else {
          this.gameResultButtonValue.winner = "Gast";
          this.gameResultButtonValue.pointsOpponent = this.dialogGameSet.pointsHome;
        }
      } else {
        this.gameResultButtonValue.winner = null;
        this.gameResultButtonValue.pointsOpponent = 0;
      }
    },
    openPlayerDialog(blockNumber, position, playerIndex, isHomePlayer) {
      this.dialogGameSet = this.getGameSet(blockNumber, position);
      this.dialogSetResult = this.getResultSetForPlayer(
        blockNumber,
        position,
        playerIndex,
        isHomePlayer
      );
      this.displayPlayerDialog = true;
      var players = isHomePlayer ? this.homePlayers : this.guestPlayers;
      var gameSet = this.game.gameSets.filter((gs) => gs.blockNumber == blockNumber);

      this.dialogPlayers = players.filter(
        (p) =>
          p.id < 0 ||
          gameSet.filter((gs) =>
            isHomePlayer
              ? gs.setResultForHomePlayers.find(
                  (set) => set.player && set.player.id == p.id
                )
              : gs.setResultForGuestPlayers.find(
                  (set) => set.player && set.player.id == p.id
                )
          ).length <= 0
      );
      this.selectedDialogPlayerId = this.dialogPlayers[0].id;
    },
    openHighlightsDialog(blockNumber, position, playerIndex, isHomePlayer) {
      this.displayHighlightsDialog = true;
      this.dialogGameSet = this.getGameSet(blockNumber, position);
      this.dialogSetResult = this.getResultSetForPlayer(
        blockNumber,
        position,
        playerIndex,
        isHomePlayer
      );

      if (this.dialogSetResult.jsonWithDetails) {
        this.dialogHighLightsModel =
          typeof this.dialogSetResult.jsonWithDetails === "string"
            ? JSON.parse(this.dialogSetResult.jsonWithDetails)
            : JSON.parse(JSON.stringify(this.dialogSetResult.jsonWithDetails));
      }
      if (!this.dialogHighLightsModel || !this.dialogHighLightsModel.highScores) {
        this.dialogHighLightsModel = {
          highScores: [
            { key: "171", value: 0 },
            { key: "180", value: 0 },
          ],
          highFinishes: [],
          shortLegs: [],
        };
      } else {
        if (!this.dialogHighLightsModel.highScores.find((item) => item.key == "171")) {
          this.dialogHighLightsModel.highScores.push({
            key: "171",
            value: 0,
          });
        }
        if (!this.dialogHighLightsModel.highScores.find((item) => item.key == "180")) {
          this.dialogHighLightsModel.highScores.push({
            key: "180",
            value: 0,
          });
        }
      }
      this.sortHighlights();
    },
    openCommentDialog(insertExistingComments) {
      if (insertExistingComments) {
        this.newComment = this.game.notes;
        this.editExistingComment = true;
      } else {
        this.newComment = "";
        this.editExistingComment = false;
      }
      this.displayCommentDialog = true;
    },
    closeResultDialog() {
      this.displayResultDialog = false;
      this.dialogGameSet.setStatus =
        (this.dialogGameSet.pointsGuest != 0 || this.dialogGameSet.pointsHome != 0) &&
        this.dialogGameSet.setResultForHomePlayers.length > 0 &&
        this.dialogGameSet.setResultForGuestPlayers.length > 0
          ? "Played"
          : "ToPlay";
      this.updateResults();
      if (!this.adminMode) {
        this.saveGame();
      }
    },
    closePlayerDialog(save) {
      if (save) {
        var selectedPlayer = this.dialogPlayers.find(
          (player) => player.id == this.selectedDialogPlayerId
        );
        this.dialogSetResult.player = selectedPlayer;
        if (selectedPlayer.id < 0) {
          if (this.homePlayers.filter((p) => p.id == selectedPlayer.id).length > 0) {
            this.dialogGameSet.setStatus = "WinForGuest";
            this.dialogGameSet.pointsHome = 0;
            this.dialogGameSet.pointsGuest = this.getMaxPoints(this.dialogGameSet);
            this.dialogGameSet.setResultForHomePlayers.forEach(
              (set) => (set.player = noPlayerHome)
            );
          } else {
            this.dialogGameSet.setStatus = "WinForHome";
            this.dialogGameSet.pointsHome = this.getMaxPoints(this.dialogGameSet);
            this.dialogGameSet.pointsGuest = 0;
            this.dialogGameSet.setResultForGuestPlayers.forEach(
              (set) => (set.player = noPlayerGuest)
            );
          }
        } else if (
          this.dialogGameSet.setResultForHomePlayers.filter(
            (s) => s.player && s.player.id < 0
          ).length <= 0 &&
          this.dialogGameSet.setResultForGuestPlayers.filter(
            (s) => s.player && s.player.id < 0
          ).length <= 0
        ) {
          this.dialogGameSet.setStatus =
            (this.dialogGameSet.pointsGuest != 0 || this.dialogGameSet.pointsHome != 0) &&
            this.dialogGameSet.setResultForHomePlayers.length > 0 &&
            this.dialogGameSet.setResultForGuestPlayers.length > 0
              ? "Played"
              : "ToPlay";
        } else {
          if (this.homePlayers.filter((p) => p.id == selectedPlayer.id).length > 0) {
            this.dialogGameSet.setResultForHomePlayers = this.dialogGameSet.setResultForHomePlayers.filter(
              (s) => s.player && s.player.id > 0
            );
            if (this.dialogGameSet.setStatus == "WinForGuest") {
              this.dialogGameSet.setStatus = "ToPlay";
            }
          }
          if (this.guestPlayers.filter((p) => p.id == selectedPlayer.id).length > 0) {
            this.dialogGameSet.setResultForGuestPlayers = this.dialogGameSet.setResultForGuestPlayers.filter(
              (s) => s.player && s.player.id > 0
            );
            if (this.dialogGameSet.setStatus == "WinForHome") {
              this.dialogGameSet.setStatus = "ToPlay";
            }
          }
        }
        if (!this.adminMode) {
          this.saveGame();
        }
      }
      this.displayPlayerDialog = false;
    },
    closeHighlightsDialog(save) {
      this.addHighScore();
      this.addHighFinish();
      this.addShortLeg();
      this.clearZeroHighlights();
      this.dialogHighLightsModel.highScores = this.dialogHighLightsModel.highScores.filter(
        (item) => item.value > 0
      );
      this.sortHighlights();
      if (save) {
        this.dialogSetResult.jsonWithDetails = this.dialogHighLightsModel;
        if (!this.adminMode) {
          this.saveGame();
        }
      }
      this.dialogHighLightsModel = {};
      this.displayHighlightsDialog = false;
    },
    deleteResult() {
      this.dialogGameSet.pointsHome = null;
      this.dialogGameSet.pointsGuest = null;
      this.updateResults();
    },
    gameResultChangedSlider(value) {
      var maxPoints = Math.round(
        this.gameMode["block" + this.dialogGameSet.blockNumber].bestOf / 2
      );
      var v = value - maxPoints;
      if (v == 0) {
        this.dialogGameSet.pointsHome = 0;
        this.dialogGameSet.pointsGuest = 0;
      } else if (v < 0) {
        this.dialogGameSet.pointsHome = maxPoints;
        this.dialogGameSet.pointsGuest = maxPoints + v;
      } else {
        this.dialogGameSet.pointsGuest = maxPoints;
        this.dialogGameSet.pointsHome = maxPoints - v;
      }
    },
    gameResultChangedButtons() {
      var maxPoints = Math.round(
        this.gameMode["block" + this.dialogGameSet.blockNumber].bestOf / 2
      );
      if (this.gameResultButtonValue.winner == "Heim") {
        this.dialogGameSet.pointsHome = maxPoints;
        this.dialogGameSet.pointsGuest = this.gameResultButtonValue.pointsOpponent;
      } else if (this.gameResultButtonValue.winner == "Gast") {
        this.dialogGameSet.pointsGuest = maxPoints;
        this.dialogGameSet.pointsHome = this.gameResultButtonValue.pointsOpponent;
      }
    },
    addHighScore() {
      if (
        this.highScoreText &&
        this.highScoreText.length >= 3 &&
        parseInt(this.highScoreText) >= 171 &&
        parseInt(this.highScoreText) <= 180
      ) {
        var existing = this.dialogHighLightsModel.highScores.find(
          (item) => item.key == this.highScoreText
        );
        if (existing) {
          if (existing.value) {
            existing.value++;
          } else {
            existing.value = 1;
          }
        } else {
          this.dialogHighLightsModel.highScores.push({
            key: this.highScoreText,
            value: 1,
          });
        }
        this.highScoreText = "";
        this.sortHighlights();
      }
    },
    addHighFinish() {
      if (
        this.highFinishText &&
        this.highFinishText.length >= 3 &&
        parseInt(this.highFinishText) >= 100 &&
        parseInt(this.highFinishText) <= 170
      ) {
        var existing = this.dialogHighLightsModel.highFinishes.find(
          (item) => item.key == this.highFinishText
        );
        if (existing) {
          if (existing.value) {
            existing.value++;
          } else {
            existing.value = 1;
          }
        } else {
          this.dialogHighLightsModel.highFinishes.push({
            key: this.highFinishText,
            value: 1,
          });
        }
        this.highFinishText = "";
        this.sortHighlights();
      }
    },
    addShortLeg() {
      if (
        this.shortLegText &&
        this.shortLegText.length >= 1 &&
        parseInt(this.shortLegText) <= 18 &&
        parseInt(this.shortLegText) >= 9
      ) {
        var existing = this.dialogHighLightsModel.shortLegs.find(
          (item) => item.key == this.shortLegText
        );
        if (existing) {
          if (existing.value) {
            existing.value++;
          } else {
            existing.value = 1;
          }
        } else {
          this.dialogHighLightsModel.shortLegs.push({
            key: this.shortLegText,
            value: 1,
          });
        }
        this.shortLegText = "";
        this.sortHighlights();
      }
    },
    clearZeroHighlights() {
      if (this.dialogGameSet) {
        if (
          this.dialogHighLightsModel.highScores &&
          this.dialogHighLightsModel.highScores.length > 0
        ) {
          this.dialogHighLightsModel.highScores = this.dialogHighLightsModel.highScores.filter(
            (item) => item.value > 0 || item.key == "180" || item.key == "171"
          );
        }
        if (this.dialogHighLightsModel.highFinishes) {
          this.dialogHighLightsModel.highFinishes = this.dialogHighLightsModel.highFinishes.filter(
            (item) => item.value > 0
          );
        }
        if (this.dialogHighLightsModel.shortLegs) {
          this.dialogHighLightsModel.shortLegs = this.dialogHighLightsModel.shortLegs.filter(
            (item) => item.value > 0
          );
        }
      }
    },
    sortHighlights() {
      if (this.dialogGameSet) {
        if (
          this.dialogHighLightsModel.highScores &&
          this.dialogHighLightsModel.highScores.length > 0
        ) {
          this.dialogHighLightsModel.highScores = this.dialogHighLightsModel.highScores.sort(
            (item1, item2) => parseInt(item2.key) - parseInt(item1.key)
          );
        }
        if (this.dialogHighLightsModel.highFinishes) {
          this.dialogHighLightsModel.highFinishes = this.dialogHighLightsModel.highFinishes.sort(
            (item1, item2) => parseInt(item2.key) - parseInt(item1.key)
          );
        }
        if (this.dialogHighLightsModel.shortLegs) {
          this.dialogHighLightsModel.shortLegs = this.dialogHighLightsModel.shortLegs.sort(
            (item1, item2) => parseInt(item1.key) - parseInt(item2.key)
          );
        }
      }
    },
    isBlockInvalid(blockNumber) {
      var isInvalid =
        this.game &&
        this.game.gameSets &&
        this.game.gameSets.filter(
          (gs) => gs.blockNumber == blockNumber && !this.isGameSetValid(gs)
        ).length > 0;
      if (!isInvalid) {
        return isInvalid;
      }
      if (this.isCupGame() && blockNumber >= 3) {
        return this.game.pointsHome < 7 && this.game.pointsGuest < 7;
      }
      return isInvalid;
    },
    isGameSetValid(gameSet) {
      if (
        (gameSet.setStatus != "WinForGuest" &&
          (!gameSet.setResultForHomePlayers ||
            gameSet.setResultForHomePlayers.filter(
              (result) => !result.player || !result.player.id
            ).length > 0)) ||
        (gameSet.setStatus != "WinForHome" &&
          (!gameSet.setResultForGuestPlayers ||
            gameSet.setResultForGuestPlayers.filter(
              (result) => !result.player || !result.player.id
            ).length > 0))
      ) {
        return false;
      }
      return (
        (gameSet.pointsHome != 0 || gameSet.pointsGuest != 0) &&
        (gameSet.setResultForHomePlayers.length > 0 ||
          gameSet.setStatus == "WinForGuest") &&
        (gameSet.setResultForGuestPlayers.length > 0 || gameSet.setStatus == "WinForHome")
      );
    },

    removeInvalidSetResults(gameSet) {
      if (gameSet.setResultForHomePlayers) {
        gameSet.setResultForHomePlayers = gameSet.setResultForHomePlayers.filter(
          (result) => result.player && result.player.id && result.player.id > 0
        );
      }
      if (gameSet.setResultForGuestPlayers) {
        gameSet.setResultForGuestPlayers = gameSet.setResultForGuestPlayers.filter(
          (result) => result.player && result.player.id && result.player.id > 0
        );
      }

      return (
        gameSet.pointsHome != 0 ||
        gameSet.pointsGuest != 0 ||
        gameSet.setResultForHomePlayers.length > 0 ||
        gameSet.setResultForGuestPlayers.length > 0
      );
    },
    updateResults() {
      if (this.game.gameSets && this.game.gameSets.length > 0) {
        var pointsHome = this.game.gameSets.filter(
          (gs) => gs.pointsHome >= this.getMaxPoints(gs)
        ).length;
        var pointsGuest = this.game.gameSets.filter(
          (gs) => gs.pointsGuest >= this.getMaxPoints(gs)
        ).length;
        this.game.pointsHome = pointsHome;
        this.game.pointsGuest = pointsGuest;
        this.setResult = pointsHome + ":" + pointsGuest;

        var legsHome = this.game.gameSets
          .filter(
            (gs) =>
              gs.pointsHome >= this.getMaxPoints(gs) ||
              gs.pointsGuest >= this.getMaxPoints(gs)
          )
          .map((gs) => gs.pointsHome)
          .reduce((prev, curr) => prev + curr, 0);
        var legsGuest = this.game.gameSets
          .filter(
            (gs) =>
              gs.pointsHome >= this.getMaxPoints(gs) ||
              gs.pointsGuest >= this.getMaxPoints(gs)
          )
          .map((gs) => gs.pointsGuest)
          .reduce((prev, curr) => prev + curr, 0);
        this.legResult = legsHome + ":" + legsGuest;
      } else {
        this.setResult =
          (this.game.pointsHome ?? "0") + ":" + (this.game.pointsGuest ?? "0");
        this.legResult = "0:0";
      }
    },
    showComments() {
      return (
        this.allowComments() ||
        (this.game && this.game.notes && this.game.notes.length > 0)
      );
    },
    allowComments() {
      if (this.adminMode || this.editMode) {
        return true;
      }
      if (this.game && this.game.gameStatus && this.game.gameStatus != "Played") {
        return true;
      }
      return false;
    },
    executeFinish() {
      this.displayFinishDialog = false;
      this.updateResults();
      if (this.game.gameStatus == "WaitingForGuestApproval") {
        this.game.gameStatus = "Played";
      } else {
        this.game.gameStatus = "WaitingForGuestApproval";
      }
      this.saveGame();
      this.editMode = false;
      this.checkEditMode();
      this.activeIndex = [0, 1, 2, 3];
      this.updateMainMenu();
    },
    cancelFinish() {
      this.displayFinishDialog = false;
    },
    addComment() {
      this.displayCommentDialog = false;
      var user = JSON.parse(localStorage.getItem("user"));
      if (this.editExistingComment) {
        this.game.notes = this.newComment;
        this.editExistingComment = false;
        this.newComment = "";
      } else {
        var commentLine =
          "--------------- " +
          new Date().toLocaleString("DE") +
          " (" +
          user.username +
          ")" +
          " --------------- ";
        if (!this.game.notes || this.game.notes.length < 1) {
          this.game.notes = commentLine + "\r\n" + this.newComment;
        } else {
          this.game.notes =
            commentLine + "\r\n" + this.newComment + "\r\n\r\n" + this.game.notes;
        }
        this.newComment = "";
        if (!this.adminMode) {
          this.saveGame();
        }
      }
    },
    cancelComment() {
      this.displayCommentDialog = false;
      this.newComment = "";
    },
    addGameReportError() {
      this.displayGameReportErrorDialog = false;
      var user = JSON.parse(localStorage.getItem("user"));
      var commentLine =
        "--------------- " +
        new Date().toLocaleString("DE") +
        " (" +
        user.username +
        ")" +
        " --------------- ";
      if (!this.game.notes || this.game.notes.length < 1) {
        this.game.notes = commentLine + "\r\n" + this.gameReportError;
      } else {
        this.game.notes =
          commentLine + "\r\n" + this.gameReportError + "\r\n\r\n" + this.game.notes;
      }
      this.game.gameStatus = "WaitingForGameManagerApproval";
      this.gameReportError = "";
      if (!this.adminMode) {
        this.saveGame();
      }
    },
    cancelGameReportError() {
      this.displayGameReportErrorDialog = false;
      this.gameReportError = "";
    },
    isShowSideBySideAllowed() {
      var rootDiv = document.getElementById("rootDiv");
      if (rootDiv) {
        var currentWidth = rootDiv.getBoundingClientRect().width;
        return currentWidth > 700;
      }
      return false;
    },
    isCupGame() {
      return (
        this.game &&
        this.game.gameDay &&
        this.game.gameDay.league &&
        this.game.gameDay.league.abbreviation &&
        this.game.gameDay.league.abbreviation == "Pokal"
      );
    },
    getMaxPoints(gameset) {
      if (gameset && this.gameMode && this.gameMode["block" + gameset.blockNumber]) {
        return Math.round(this.gameMode["block" + gameset.blockNumber].bestOf / 2);
      }
      return 0;
    },
  },
};
</script>

<style lang="css">
.p-accordion-content {
  padding: 0.3rem !important;
}
#playerDiv {
  font-size: 22px;
}
.playerButton {
  font-size: 22px;
}
.highLightButton {
  font-size: 20px;
}
@media screen and (max-width: 420px) {
  #playerDiv {
    font-size: 16px;
  }
  .playerButton {
    font-size: 16px;
  }
  .p-accordion-content {
    padding: 0.1rem !important;
  }
  .p-tabview .p-tabview-panels {
    padding: 1rem 0.1rem !important;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 1rem 0.7rem;
  }
  .p-tabview-title {
    font-size: 14px;
  }
  #tabHeader {
    font-size: 14px;
  }
  .highLightButton {
    font-size: 16px;
  }
}
@media screen and (max-width: 360px) {
  #playerDiv {
    font-size: 14px;
  }
  .playerButton {
    font-size: 14px;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 1rem 0.2rem;
  }
  .highLightButton {
    font-size: 12px;
  }
}
.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 0.4rem 0.8rem !important;
}
.p-dialog .p-dialog-footer button {
  margin: 0.3rem 1.5rem 0 0 !important;
}
</style>
